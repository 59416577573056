import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import Button from "../components/button"
import Module from "../styles/modules/card.module.scss"

const NotFoundPage = () => {
  const { t } = useTranslation("404")

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div
        style={{
          position: "relative",
          backgroundImage: `url(/404.svg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <div className={`${Module.card} color-black text--sm`}>
          <h1 className="text--sm sp-mb-3">{t("error")}</h1>
          <div>{t("error_description")}</div>
          <div className={Module.card__link}>
            <Button to="/" className="button button--light button--icon">
              {t("button")}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
